<template>
  <div v-if="count" class="pagination">
    <template v-if="showFirst">
      <button class="btn btn-outline-primary" @click="goToPage(1)">1</button>
      <span>...</span>
    </template>

    <button
      v-for="n in pages"
      :key="n"
      class="btn"
      :class="{
        'btn-outline-primary': n !== currentPage,
        'btn-primary': n === currentPage,
      }"
      @click="goToPage(n)"
    >
      {{ n }}
    </button>

    <template v-if="showLast">
      <span>...</span>
      <button class="btn btn-outline-primary" @click="goToPage(numberPages)">
        {{ numberPages }}
      </button>
    </template>
  </div>
</template>

<script>
import { first, last } from "lodash";

export default {
  props: {
    count: Number,
    limit: Number,
  },
  computed: {
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
    numberPages() {
      return Math.ceil(this.count / this.limit);
    },
    back() {
      return this.goToPage(this.currentPage - 1);
    },
    next() {
      return this.goToPage(this.currentPage + 1);
    },
    pages() {
      const pages = [];
      for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
        if (i > 0 && i <= this.numberPages) {
          pages.push(i);
        }
      }
      return pages;
    },
    showFirst() {
      return first(this.pages) !== 1;
    },
    showLast() {
      return last(this.pages) !== this.numberPages;
    },
  },
  methods: {
    goToPage(n) {
      this.$router.push({ query: { ...this.$route.query, page: n } });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -4px;

  button {
    margin: 0 4px;
    padding: 0;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.btn-outline-info {
      border: none;
    }

    &:disabled {
      opacity: 0.5;
      filter: grayscale(0.1);
    }
  }
}
</style>
