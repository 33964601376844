<template>
  <div v-if="loading" class="text-center p-5">
    <div class="spinner-border text-primary"></div>
  </div>

  <component v-else-if="$route.meta.template" :is="$route.meta.template">
    <router-view :key="$route.fullPath" />
  </component>

  <router-view v-else />

  <a href="https://wa.me/557181907474" class="btn-whatsapp" target="_blank">
    <i class="fa-brands fa-whatsapp"></i>
  </a>
</template>

<script>
import { getToken } from "@/api";
import { mapState } from "vuex";

let firstLoad = true;

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("accounts", ["user", "logged"]),
  },
  watch: {
    "$route.name": function () {
      if (!this.logged && !this.$route.meta.open) {
        window.location = `${window.location.origin}/login?path=${window.location.pathname}`;
      }
    },
    "$route.fullPath": function () {
      if (!firstLoad) {
        window.scrollTo(0, 0);
      }
      firstLoad = false;
    },
  },
  mounted() {
    if (getToken()) {
      this.loading = true;

      this.$store.dispatch("accounts/get").finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style lang="scss">
.btn-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #25d366;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-decoration: none;
}
</style>
