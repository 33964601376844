import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { init } from "./api";

import ElementPlus from "element-plus";
import ptBr from "element-plus/es/locale/lang/pt-br";
import Maska from "maska";

import "./assets/scss/app.scss";
import "element-plus/dist/index.css";
import "plyr/dist/plyr.css";

import { registerComponents } from "./components";

init();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: ptBr,
});
app.use(Maska);

registerComponents(app);

app.mount("#app");
