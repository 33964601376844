import { createRouter, createWebHistory } from "vue-router";
import Default from "../templates/Default.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/minha-conta",
    name: "account",
    component: () => import("../views/account.vue"),
    meta: {
      template: Default,
    },
  },

  // cursos
  {
    path: "/curso/:id/:slug",
    name: "course",
    component: () => import("../views/course.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/aula/:id/:slug",
    name: "lesson",
    component: () => import("../views/lesson.vue"),
    meta: {
      template: Default,
    },
  },

  // ebooks
  {
    path: "/ebooks",
    name: "ebooks",
    component: () => import("../views/ebooks.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/ebooks/:id/:slug",
    name: "ebook",
    component: () => import("../views/ebook.vue"),
  },

  // blog
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/blog/index.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/blog/:id/:slug",
    name: "blog-post",
    component: () => import("../views/blog/post.vue"),
    meta: {
      template: Default,
    },
  },

  // blog
  {
    path: "/comunidade",
    name: "forum",
    component: () => import("../views/forum/index.vue"),
    meta: {
      template: Default,
    },
  },
  {
    path: "/comunidade/:id/:slug",
    name: "forum-topic",
    component: () => import("../views/forum/topic.vue"),
    meta: {
      template: Default,
    },
  },

  // audios
  {
    path: "/audios",
    name: "podcasts",
    component: () => import("../views/podcasts.vue"),
    meta: {
      template: Default,
    },
  },

  // Autenticação
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/reset-password.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/politica-de-privacidade",
    name: "privacy-policy",
    component: () => import("../views/privacy.vue"),
    meta: {
      open: true,
    },
  },
  {
    path: "/termos-de-uso",
    name: "terms",
    component: () => import("../views/terms.vue"),
    meta: {
      open: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
