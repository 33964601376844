import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    categories: [],
    posts: [],
    post: {},
    count: 0,
  }),

  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setPosts(state, posts) {
      state.posts = posts;
    },
    setPost(state, post) {
      state.post = post;
    },
    setCount(state, count) {
      state.count = count;
    },
  },

  actions,
};
