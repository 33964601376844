import { get } from "lodash";

export default {
  getCategories(context) {
    if (get(context.state, "categories.length")) return;
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/blog/categories`)
        .then((response) => {
          context.commit("setCategories", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getAll(context, params) {
    const query = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/blog/posts?${query}`)
        .then((response) => {
          context.commit("setPosts", response.data.results);
          context.commit("setCount", response.data.count);
          resolve();
        })
        .catch(reject);
    });
  },
  get(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/blog/posts/${id}`)
        .then((response) => {
          context.commit("setPost", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
};
