import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    categories: [],
    courses: [],
    course: {},
    loadedModule: null,
    lessons: [],
    lesson: {},
    supportMaterials: [],
    live: {},
    watching: [],
  }),

  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCourses(state, courses) {
      state.courses = courses;
    },
    setCourse(state, course) {
      state.course = course;
    },
    setLoadedModule(state, module) {
      state.loadedModule = module;
    },
    setLessons(state, lessons) {
      state.lessons = lessons;
    },
    setLesson(state, lesson) {
      state.lesson = lesson;
    },
    setSupportMaterials(state, supportMaterials) {
      state.supportMaterials = supportMaterials;
    },
    setLive(state, live) {
      state.live = live;
    },
    setWatching(state, watching) {
      state.watching = watching;
    },
  },

  actions,
};
