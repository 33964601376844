<template>
  <div class="user-photo" :style="imgStyle">
    <i v-if="!photo" class="fa-regular fa-user" :style="iconStyle"></i>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
      default: 42,
    },
  },
  computed: {
    imgStyle() {
      const size = `${this.size}px`;
      const style = {
        "max-width": size,
        "min-width": size,
        "max-height": size,
        "min-height": size,
      };
      if (this.photo) {
        style["background-image"] = `url("${this.photo}")`;
      }
      return style;
    },
    iconStyle() {
      return {
        "font-size": `${parseInt((3 * this.size) / 5)}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-photo {
  border-radius: 100%;
  background-color: #06d09c;
  color: white !important;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
