<template>
  <div v-if="live && live.id" class="templates-live-bar bg-primary">
    <div class="container d-flex align-items-center">
      <img class="d-none d-md-block" :src="live.cover" />
      <strong class="mx-4 pe-4 border-end">
        AULA<br />
        AO VIVO
      </strong>
      <div class="flex-fill d-none d-md-flex flex-column pe-4">
        <strong>{{ live.name }}</strong>
        <small class="me-2">{{ live.module.course.name }}</small>
      </div>
      <router-link
        class="btn btn-dark d-inline-flex align-items-center ms-auto"
        :to="{ name: 'lesson', params: { id: live.id, slug: live.slug } }"
      >
        <i class="fa-regular fa-play lead me-2"></i>
        <strong>Assistir</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("courses", ["live"]),
  },
  mounted() {
    this.$store.dispatch("courses/getLive");
  },
};
</script>

<style lang="scss" scoped>
.templates-live-bar {
  color: white;
  padding: 16px 0;

  img {
    object-fit: cover;
    max-width: 100px;
    max-height: 60px;
    min-width: 100px;
    min-height: 60px;
  }

  strong {
    border-color: white;
    font-size: 18px;
  }
}
</style>
