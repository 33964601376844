<template>
  <div class="templates-main-menu">
    <router-link :to="{ name: 'index' }" @click="$emit('close')">
      Cursos
    </router-link>
    <router-link :to="{ name: 'ebooks' }" @click="$emit('close')">
      Ebooks
    </router-link>
    <router-link :to="{ name: 'podcasts' }" @click="$emit('close')">
      Áudios
    </router-link>
    <router-link :to="{ name: 'blog' }" @click="$emit('close')">
      Reflexões
    </router-link>
    <router-link :to="{ name: 'forum' }" @click="$emit('close')">
      Fórum
    </router-link>

    <router-link
      class="d-lg-none"
      :to="{ name: 'account' }"
      @click="$emit('close')"
    >
      Minha conta
    </router-link>
    <a class="d-lg-none" href="#" @click="$emit('certificates')">
      Meus certificados
    </a>
    <a class="d-lg-none" href="#" @click="$emit('signout')"> Sair </a>
  </div>
</template>

<style lang="scss" scoped>
.templates-main-menu {
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    margin-right: 24px;

    @media screen and (max-width: 991px) {
      margin-bottom: 16px;
    }

    &:not(.router-link-exact-active) {
      color: white;
      opacity: 0.7;
      font-weight: normal;
    }
  }
}
</style>
