<template>
  <div class="templates-top-bar bg-dark">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-8 d-flex align-items-center">
          <router-link :to="{ name: 'index' }">
            <img src="@/assets/images/logo.png" class="logo me-5" />
          </router-link>
          <main-menu class="d-none d-lg-flex" />
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end">
          <button class="btn d-lg-none" @click="drawer = true">
            <i class="fa-regular fa-bars fa-lg text-light"></i>
          </button>

          <el-dropdown v-if="user" trigger="click" class="d-none d-lg-block">
            <span class="el-dropdown-link text-light">
              Olá, {{ user.name || "aluno" }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="text-center px-4 py-3">
                  <h6 class="mb-0">{{ user.name }}</h6>
                  <small class="text-secondary">{{ user.email }}</small>
                </div>
                <el-dropdown-item @click="$router.push({ name: 'account' })"
                  >Minha conta</el-dropdown-item
                >
                <el-dropdown-item @click="$emit('certificates')"
                  >Meus certificados</el-dropdown-item
                >
                <el-dropdown-item @click="$emit('signout')">
                  Sair
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>

  <el-drawer v-model="drawer" :with-header="false">
    <div class="row mb-5">
      <div class="col-6">
        <router-link :to="{ name: 'index' }">
          <img src="@/assets/images/logo.png" class="img-fluid" />
        </router-link>
      </div>
      <div class="col-6 text-end">
        <button class="btn" @click="drawer = false">
          <i class="fa-regular fa-times fa-lg text-light"></i>
        </button>
      </div>
    </div>

    <main-menu
      @signout="$emit('signout')"
      @certificates="$emit('certificates')"
      @close="drawer = false"
    />
  </el-drawer>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "./MainMenu";

export default {
  components: { MainMenu },
  data() {
    return {
      drawer: false,
      profile: false,
    };
  },
  computed: {
    ...mapState("accounts", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.templates-top-bar {
  padding: 20px 0;
  border-bottom: 3px solid black;

  .logo {
    height: 70px;
    width: auto;
  }

  input {
    min-height: 44px;
    border: none;
    border-radius: 44px;
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #73778c;
    margin: 0 12px;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    i {
      font-size: 28px;
    }
  }

  .profile-avatar {
    margin-right: 0;
  }
}
</style>
