<template>
  <footer class="site-footer bg-dark py-4 text-center small">
    <div
      class="container d-flex flex-column flex-lg-row align-items-center justify-content-between gap-3"
    >
      <img src="@/assets/images/assinatura.svg" />
      <span class="text-secondary"
        >© Augustus Nicodemus {{ year }}. Todos os direitos reservados.</span
      >
      <a
        class="text-secondary"
        target="_blank"
        href="https://privacidade.augustusnicodemus.com.br/politica-de-privacidade/"
        >Política de Privacidade</a
      >
      <a
        class="text-secondary"
        target="_blank"
        href="https://privacidade.augustusnicodemus.com.br/termos-de-uso/"
        >Termos de Uso</a
      >
    </div>
  </footer>
</template>

<script setup>
const year = new Date().getFullYear();
</script>

<style lang="scss" scoped>
.site-footer {
  a {
    text-decoration: none;
  }
}
</style>
