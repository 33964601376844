<template>
  <el-dialog v-model="visible" custom-class="templates-news-dialog text-center">
    <h5>🎉 Queridos membros, temos uma ótima notícia!</h5>
    <div class="bg-primary text-dark p-4 rounded my-4">
      Agora, todos os nossos cursos têm certificado de extensão<br />
      <strong>reconhecido pelo MEC!</strong>
    </div>
    <p>
      Você que já fez algum dos cursos do Rev. Augustus e quer saber como
      solicitar seu certificado, siga os passos a seguir:
    </p>
    <a
      href="https://app.augustusnicodemus.com.br/blog/247/emita-seu-certificado-digital-reconhecido-pelo-mec"
      class="btn btn-success rounded-pill"
      target="_blank"
    >
      QUERO EMITIR MEU CERTIFICADO RECONHECIDO PELO MEC
    </a>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";

const key = "newsDialog_2";
const show = !localStorage.getItem(key);
const visible = ref(show);
localStorage.setItem(key, "true");
</script>

<style lang="scss">
.el-dialog.templates-news-dialog {
  width: 90% !important;
  max-width: 600px !important;
}
</style>
