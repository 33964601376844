export default {
  // TOPICS
  resetTopics(context) {
    context.commit("setTopics", []);
    context.commit("setCount", 0);
  },
  getLikedTopics(context) {
    if (context.state.likedTopics.length) return;
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/topic?ordering=-like&limit=2`)
        .then((response) => {
          context.commit("setLikedTopics", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getCommentedTopics(context) {
    if (context.state.commentedTopics.length) return;
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/topic?ordering=-count&limit=2`)
        .then((response) => {
          context.commit("setCommentedTopics", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getCategories(context) {
    if (context.state.categories.length) return;
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/categories`)
        .then((response) => {
          context.commit("setCategories", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },

  getTopics(context, params) {
    const queryString = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/topic?${queryString}`)
        .then((response) => {
          context.commit("setTopics", response.data.results);
          context.commit("setCount", response.data.count);
          resolve();
        })
        .catch(reject);
    });
  },
  getTopic(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/topic/${id}`)
        .then((response) => {
          context.commit("setTopic", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  addTopic(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/forum/topic", data).then(resolve).catch(reject);
    });
  },
  updateTopic(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/forum/topic/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  removeTopic(context, id) {
    return new Promise((resolve, reject) => {
      window.axios.delete(`/forum/topic/${id}`).then(resolve).catch(reject);
    });
  },

  // COMMENTS
  resetComments(context) {
    context.commit("setComments", []);
    context.commit("setCount", 0);
  },
  getComments(context, params) {
    const queryString = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/comments?${queryString}`)
        .then((response) => {
          context.commit("addComments", response.data.results);
          context.commit("setCount", response.data.count);
          resolve();
        })
        .catch(reject);
    });
  },
  getComment(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/forum/comments/${id}`)
        .then((response) => {
          context.commit("setComment", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  addComment(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/forum/comments", data).then(resolve).catch(reject);
    });
  },
  updateComment(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/forum/comments/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  removeComment(context, id) {
    return new Promise((resolve, reject) => {
      window.axios.delete(`/forum/comments/${id}`).then(resolve).catch(reject);
    });
  },

  // INTERACTIONS
  interact(context, data) {
    return new Promise((resolve, reject) => {
      window.axios.post("/forum/interaction", data).then(resolve).catch(reject);
    });
  },
};
