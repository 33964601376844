<template>
  <div class="expired-message">
    <div class="text-center">
      <h5 class="mb-4">Sua jornada de aprendizado aguarda!</h5>
      <p>Parece que sua assinatura expirou. Não perca o acesso a:</p>
    </div>
    <div class="checklist my-3">
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        Cursos aprofundados
      </div>
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        Ebooks exclusivos
      </div>
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        Áudios enriquecedores
      </div>
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        Reflexões semanais
      </div>
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        Fórum de perguntas
      </div>
      <div class="item">
        <i class="fa-solid fa-check text-success fa-lg me-2"></i>
        E muito mais...
      </div>
    </div>
    <div class="text-center">
      <p>
        Tudo isso de
        <span class="text-decoration-line-through">R$ 5.000</span> por apenas
        <span class="text-success">R$ 67/mês.</span>
      </p>
      <p>Renove agora e continue a crescer na Palavra!</p>
      <a
        href="https://pay.skeap.com.br/I0QO8E?plan=o9ckcswu&sck=aviso-exp"
        class="btn btn-success d-block w-100 my-4"
      >
        Clique aqui para renovar
      </a>
      <button class="btn text-secondary" @click="signout">Sair</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    signout() {
      this.$store.dispatch("accounts/signout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.expired-message {
  .checklist {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
    }
  }
}
</style>
