<template>
  <div class="file-input">
    <input
      type="file"
      style="display: none"
      ref="fileInputElement"
      @change="pickFile"
    />

    <button
      v-if="!url"
      class="btn btn-add"
      type="button"
      :style="buttonStyle"
      @click="openFilePicker"
    >
      <span>Adicionar arquivo</span>
      <i class="fa-regular fa-file"></i>
    </button>

    <div v-else class="card btn-add">
      <div class="card-body">
        {{ fileName }}
      </div>

      <div class="action-buttons">
        <button type="button" class="btn btn-primary" @click="openFilePicker">
          <i class="fa-regular fa-pencil"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="$emit('pick', null)"
        >
          <i class="fa-regular fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageInput from "./ImageInput";
import { last } from "lodash";

export default {
  extends: ImageInput,
  props: {
    url: String,
  },
  data() {
    return {
      selectedFileName: null,
    };
  },
  computed: {
    fileName() {
      return this.selectedFileName
        ? this.selectedFileName
        : last((this.url || "").split("/"));
    },
  },
  methods: {
    validateFileSize(file) {
      if (file.size > 10000000) {
        this.$message.error("Arquivo muito grande. Tamanho máximo: 1MB");
        return true;
      }
      return false;
    },
    validateFileType(file) {
      if (file) {
        this.selectedFileName = file.name;
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input {
  button {
    background: #d8dcff;
    border: 1px solid #c2c5d6;
    border-radius: 10px;
    color: #2a3180;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(1.05);
    }
  }

  .action-buttons {
    position: absolute;
    top: 8px;
    right: 8px;

    button {
      margin: 8px;
      padding: 8px;

      i {
        font-size: 24px;
      }
    }
  }

  .btn-add {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    i {
      font-size: 30px;
    }
  }
}
</style>
