import { get } from "lodash";

export default {
  getCategories(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses/categories")
        .then((response) => {
          context.commit("setCategories", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getAll(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses?limit=9999")
        .then((response) => {
          context.commit("setCourses", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  get(context, id) {
    if (parseInt(id) === get(context.state, "course.id")) {
      return;
    }
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/${id}`)
        .then((response) => {
          context.commit("setCourse", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getLessons(context, module) {
    if (get(context.state, "loadedModule") === module) {
      return;
    }
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/lessons/${module}?limit=9999`)
        .then((response) => {
          context.commit("setLoadedModule", module);
          context.commit("setLessons", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getLesson(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/lessons/${id}/get`)
        .then((response) => {
          context.commit("setLesson", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getSupportMaterials(context, courseId) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/support-material/${courseId}`)
        .then((response) => {
          context.commit("setSupportMaterials", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  getLive(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses/live")
        .then((response) => {
          context.commit("setLive", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getWatching(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/courses/lessons/watching?limit=4")
        .then((response) => {
          context.commit("setWatching", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
};
