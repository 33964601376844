import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    ebooks: [],
    ebook: {},
  }),

  mutations: {
    setEbooks(state, ebooks) {
      state.ebooks = ebooks;
    },
    setEbook(state, ebook) {
      state.ebook = ebook;
    },
  },

  actions,
};
