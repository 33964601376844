export default {
  getAll(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/ebooks`)
        .then((response) => {
          context.commit("setEbooks", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },
  get(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/ebooks/${id}`)
        .then((response) => {
          context.commit("setEbook", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
};
