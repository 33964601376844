import actions from "./actions";

export default {
  namespaced: true,

  state: () => ({
    podcasts: [],
    categories: [],
    count: 0,
  }),

  mutations: {
    setPodcasts(state, podcasts) {
      state.podcasts = podcasts;
    },
    setCount(state, count) {
      state.count = count;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
  },

  actions,
};
