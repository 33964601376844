<template>
  <div
    class="form-group"
    :class="{ 'has-icon': !!icon, 'is-empty': !innerValue, readonly }"
  >
    <label :for="id" v-if="label">{{ label }}</label>
    <i v-if="icon" :class="'icon ' + icon"></i>

    <el-select
      v-if="select"
      class="w-100"
      :class="classObject"
      :id="id"
      v-model="innerValue"
      :filterable="filterable"
      :clearable="nullable"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="nope"
    >
      <el-option
        v-for="(option, index) in options"
        :key="index"
        :label="optionLabelFunction(option)"
        :value="option.id"
      />
    </el-select>
    <quill-editor
      v-else-if="html"
      v-model:content="innerValue"
      content-type="html"
      class="form-control p-0"
      :class="classObject"
      :id="id"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :toolbar="[
        'bold',
        'italic',
        'underline',
        'blockquote',
        { list: 'ordered' },
        { list: 'bullet' },
      ]"
    />
    <textarea
      v-else-if="textarea"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <input
      v-else
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
      v-maska="mask"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <div v-if="showError" class="invalid-feedback">{{ errors[0] }}</div>
  </div>
</template>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: { QuillEditor },
  props: {
    html: Boolean,
    textarea: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [String, Array],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: String,
    modelValue: {
      type: [String, Number],
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false,
    },
    disableAutocomplete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    filterable: Boolean,
    nullable: {
      type: Boolean,
      default: false,
    },
    optionLabelFunction: {
      type: Function,
      default: (item) => item.name,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      innerValue: this.modelValue,
      showError: false,
    };
  },
  computed: {
    classObject() {
      const result = { "is-invalid": this.showError };
      result[this.customClass] = true;
      return result;
    },
  },
  watch: {
    errors(newVal) {
      if (newVal && newVal.length) {
        this.showError = true;
      }
    },
    innerValue(newVal) {
      if (newVal != this.modelValue) {
        this.showError = false;
        this.$emit("update:modelValue", newVal);
      }
    },
    modelValue(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    },
  },
};
</script>

<style lang="scss">
.form-group {
  position: relative;

  &.readonly {
    .form-control {
      opacity: 0.5;
    }
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 38px;
      left: 4px;
    }

    .form-control {
      padding-left: 32px;
    }
  }

  &.is-empty {
    select {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .ql-container.ql-snow {
    border: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ql-toolbar.ql-snow {
    border: none !important;
    background-color: #161616;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ql-editor.ql-blank::before {
    color: #6c757d;
  }
}
</style>
