import { createStore } from "vuex";
import accounts from "./accounts";
import courses from "./courses";
import podcasts from "./podcasts";
import comments from "./comments";
import progress from "./progress";
import ebooks from "./ebooks";
import blog from "./blog";
import forum from "./forum";

export default createStore({
  modules: {
    accounts,
    courses,
    podcasts,
    comments,
    progress,
    ebooks,
    blog,
    forum,
  },
});
