<template>
  <div class="image-input">
    <input
      type="file"
      style="display: none"
      ref="fileInputElement"
      @change="pickFile"
      accept="image/png, image/jpeg"
    />

    <button type="button" :style="buttonStyle" @click="openFilePicker">
      <i v-if="!url" class="the-icon" :class="icon"></i>

      <div class="bottom-bar bg-primary" v-html="bottomHtml" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    icon: {
      type: String,
      default: "fa-regular fa-user",
    },
    bottomHtml: {
      type: String,
      default: "<i class='fa-regular fa-pencil'></i> Editar",
    },
  },
  computed: {
    buttonStyle() {
      const style = {};
      if (this.url) {
        style["background-image"] = `url(${this.url})`;
      }
      return style;
    },
  },
  methods: {
    openFilePicker() {
      this.$refs.fileInputElement.click();
    },
    pickFile() {
      const selectedFile = this.$refs.fileInputElement.files[0];
      if (selectedFile.size > 1000000) {
        this.$message.error("Imagem muito grande. Tamanho máximo: 1MB");
        return;
      }
      if (
        selectedFile &&
        ["image/png", "image/jpeg"].includes(selectedFile.type)
      ) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.$emit("pick", reader.result);
          },
          false
        );
        reader.readAsDataURL(selectedFile);
      } else {
        this.$message.error("Formato de arquivo inválido");
      }
      this.$refs.fileInputElement.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input {
  button {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    border-radius: 100%;
    background-color: #e3dfe7;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border: none;
    position: relative;
    color: white;
    font-size: 80px;

    .the-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .bottom-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 14px;
      opacity: 0.6;

      ::v-deep i {
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }
}
</style>
