import actions from "./actions";

export default {
  namespaced: true,
  state: {
    progress: [],
  },
  mutations: {
    setProgress(state, progress) {
      state.progress = progress;
    },
  },
  actions,
};
