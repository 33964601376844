<template>
  <div class="templates-my-certificates">
    <div
      v-for="(item, index) in user.certifications"
      :key="index"
      class="d-flex justify-content-between align-items-center mt-3"
    >
      <span>{{ item.course.name }}</span>
      <span>
        <a :href="item.url" class="btn btn-success">
          <i class="fa-solid fa-download me-2"></i>
          Baixar
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("accounts", ["user"]),
  },
};
</script>
